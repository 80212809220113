import styles from "./index.module.css";
import UnderLineButton from "../../elements/UnderLineButton";
import H1 from "../../typography/H1";
import { useEffect, useState } from "react";
import { approveUser, blockUser, deleteUser, fetchAllUsersByFilters } from "../../../utils/apis.utils";
import toast from "react-hot-toast";

const RequestAccess = (props)=>{
    const [prospects,setProspects] = useState();
    const [refresh,setRefresh] = useState(false);

    useEffect(()=>{
        fetchAllUsersByFilters({role:'prospect'})
        .then(res=>{
            setProspects(res?.data?.data);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[refresh]);

    const toggleUser = ()=>setRefresh(state=>!state);

    const approveUserHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await approveUser(id);
            toggleUser();
            toast.success("Prospect succesfully approved!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }

    const deleteProspect = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await deleteUser(id);
            toggleUser();
            toast.success("Prospect succesfully deleted!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }

    const blockUserHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await blockUser(id);
            toggleUser();
            toast.success("Prospect succesfully blocked!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    return <div className={styles["container"]}>
        <div className={styles["heading"]}>
            <div>
                <H1 type="heading-small">Prospect List</H1>
            </div>
            <div>
                
            </div>
        </div>
        <div className={styles["count"]}>
            {prospects?.length!==0 && <>Showing {prospects?.length} users</>}
        </div>
        {prospects?.length!==0 && window.innerWidth > 850 &&<div className={styles["content"]}>
            <div className={styles["table"]}>
                <div className={styles["head-row"]}>
                    <div>Name</div>
                    <div>Phone Number</div>
                    <div>Email Address</div>
                    <div>Is Realtor</div>
                    <div>With Realtor</div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    prospects?.map(prospect=>{
                        return <div className={styles["rows"]}>
                        <div className={styles["row"]}>
                            <div>{prospect?.first_name} {prospect?.last_name}</div>
                            <div>{prospect?.phone}</div>
                            <div>{prospect?.email}</div>
                            <div>{prospect?.isRealtor?"Yes":"No"}</div>
                            <div>{prospect?.isWorkingWithRealtor?"Yes":"No"}</div>
                            <div>
                                <UnderLineButton onClick={approveUserHandler} id={prospect?._id}>
                                    approve
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProspect} id={prospect?._id}>
                                    reject
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={blockUserHandler} id={prospect?._id}>
                                    block
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth <= 850 &&<div className={styles["content"]}>
            <div className={styles["table"]}>
                {
                    prospects?.map(prospect=>{
                        return <div className={styles["mobile-rows"]}>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Name</div>
                                <div>{prospect?.first_name} {prospect?.last_name}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Phone</div>
                                <div>{prospect?.phone}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Email</div>
                                <div>{prospect?.email}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>isRealtor</div>
                                <div>{prospect?.isRealtor?"Yes":"No"}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>withRealtor</div>
                                <div>{prospect?.isWorkingWithRealtor?"Yes":"No"}</div>
                            </div>
                            <div>
                                <UnderLineButton onClick={approveUserHandler} id={prospect?._id}>
                                    approve
                                </UnderLineButton>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={deleteProspect} id={prospect?._id}>
                                    reject
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={blockUserHandler} id={prospect?._id}>
                                    block
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {
            prospects?.length==0 && <div className={styles["no-one"]}>
                No prospects found!
            </div>

        }
    </div>
}
export default RequestAccess;