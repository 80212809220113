import styles from "./index.module.css";
import UnderLineButton from "../../elements/UnderLineButton";
import H1 from "../../typography/H1";
import { useEffect, useState } from "react";
import {  copyProject, copyVariant, copyVariantGuest, deleteProject, deleteVariant, deleteVariantGuest, fetchAllVariantsByFilters, fetchAllVariantsByFiltersGuest } from "../../../utils/apis.utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import DropDown2 from "../../elements/Dropdown2";
import Button from "../../elements/Button";
import { baseURL, permTokenIdentified, projectBase, tokenIdentified, userBase } from "../../../utils/constant";
import toast from "react-hot-toast";

const VariantManagementGuest = (props)=>{
    const [prospects,setProspects] = useState();
    const [refresh,setRefresh] = useState(false);
    const [selected,setSelected] = useState(0);

    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();

    useEffect(()=>{
        console.log(searchParams);
        let of = searchParams.get("of");

        let pjt;
        console.log(searchParams.get("pjt"));
        if(searchParams.get("pjt")){
        pjt = searchParams.get("pjt");
        localStorage.setItem(permTokenIdentified,pjt);
        }

        if(searchParams.get("name")){
            localStorage.setItem("projectName",searchParams.get("name"));
        }

        console.log(of);
        if(!of) return navigate(searchParams.get("backtrace"));

        fetchAllVariantsByFiltersGuest({project:of})
        .then(res=>{
            setProspects(res?.data?.data);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[refresh,selected]);

    const toggleUser = ()=>setRefresh(state=>!state);

    const deleteProjectHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await deleteVariantGuest(id);
            toggleUser();
            toast.success("Variant deleted succesfully!")
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    
    const updateUserHandler = (event)=>{
        let id = event?.target?.dataset?.id;

        if(!id) return toast.error("Fail - refresh and try again!");

        navigate("/guest/variant-form?of="+searchParams.get("of")+"&isEdit=true&backtrace=/guest/project?of="+searchParams.get("of")+"&pjt="+searchParams.get("pjt")+"&vof="+id);
    }

    const addUserHandler = (event)=>{
        navigate("/guest/variant-form?of="+searchParams.get("of")+"&isEdit=false&backtrace=/guest/project?of="+searchParams.get("of")+"&pjt="+searchParams.get("pjt"));
    }

    const copyProjectHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await copyVariantGuest(id);
            toggleUser();
            toast.success("Variant copied succesfully!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    return <div className={styles["container"]}>
        <div className={styles["heading"]}>
            <div>
                <H1 type="heading-small">Variant List For Project {localStorage.getItem("projectName")}</H1>
            </div>
            {window.innerWidth>850 &&<div className={styles["filters"]}>
                    {/* <div>
                       <Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} onClick={backToProjectHandler}>Back</Button>
                    </div> */}
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add Variant</Button>
                    </div>
            </div>}
        </div>
        <div className={styles["count"]}>
            {prospects?.length!==0 && <>Showing {prospects?.length} variants</>}
        </div>
        {window.innerWidth<=850 && <div className={styles["filters"]}>
                    {/* <div>
                       <Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} onClick={backToProjectHandler}>Back</Button>
                    </div> */}
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add Variant</Button>
                    </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth>850 && <div className={styles["content"]}>
            <div className={styles["table"]}>
                <div className={styles["head-row"]}>
                    <div>Beds</div>
                    <div>Baths</div>
                    <div>Price</div>
                    <div>Sqft</div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    prospects?.map(prospect=>{
                        return <div className={styles["rows"]}>
                        <div className={styles["row"]}>
                            <div>{prospect?.beds}</div>
                            <div>{prospect?.baths}</div>
                            <div>{prospect?.price}</div>
                            <div>{prospect?.sqft}</div>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProjectHandler} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={copyProjectHandler} id={prospect?._id}>
                                    copy
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth<=850 &&<div className={styles["content"]}>
            <div className={styles["table"]}>
                {
                    prospects?.map(prospect=>{
                        return <div className={styles["mobile-rows"]}>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Beds</div>
                                <div>{prospect?.beds}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Baths</div>
                                <div>{prospect?.baths}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Price</div>
                                <div>{prospect?.price}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Sqft</div>
                                <div>{prospect?.sqft}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProjectHandler} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={copyProjectHandler} id={prospect?._id}>
                                    copy
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {
            prospects?.length==0 && <div className={styles["no-one"]}>
                No variants found!
            </div>
        }
    </div>
}
export default VariantManagementGuest;