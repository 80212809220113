import axios from "axios";
import { baseURL, projectBase, userBase, variantBase } from "./constant";
import userAxios from "./axios/user.axios.js";

export const getDemoApi = "/api/demo";

export const registerUser = async (data)=>{
    return await userAxios.post(baseURL+userBase+"/register",data);
}
export const loginUser = async (data)=>{
    return await axios.post(baseURL+userBase+"/login",data);
}
export const requestSmsOTP = async ()=>{
    return await userAxios.get(baseURL+userBase+"/otp/phone");
}
export const verifySmsOTP = async (body)=>{
    return await userAxios.post(baseURL+userBase+"/verify/phone",body);
}
export const fetchAllUsersByFilters = async (filter)=>{
    if(!filter) return;

    let filterString = "?";

    for(let i=0;i<Object.keys(filter)?.length;i++){
        filterString+=Object.keys(filter)[i]+"="+filter[Object.keys(filter)[i]]+"&";
    }

    return await userAxios.get(baseURL+userBase+filterString);
}
export const approveUser = async (id)=>{
    return await userAxios.patch(baseURL+userBase+"/"+id+"/approve");
}
export const blockUser = async (id)=>{
    return await userAxios.patch(baseURL+userBase+"/"+id+"/block");
}
export const unblockUser = async (id)=>{
    return await userAxios.patch(baseURL+userBase+"/"+id+"/unblock");
}
export const deleteUser = async (id)=>{
    return await userAxios.delete(baseURL+userBase+"/"+id);
}
export const fetchUserById = async (id)=>{
    return await userAxios.get(baseURL+userBase+"/"+id);
}
export const updateUserById = async (data,id)=>{
    return await userAxios.patch(baseURL+userBase+"/"+id,data);
}

export const fetchAllProjectsByFilters = async (filter)=>{
    if(!filter) return;

    let filterString = "?";

    for(let i=0;i<Object.keys(filter)?.length;i++){
        filterString+=Object.keys(filter)[i]+"="+filter[Object.keys(filter)[i]]+"&";
    }

    return await userAxios.get(baseURL+projectBase+filterString);
}
export const deleteProject = async (id)=>{
    return await userAxios.delete(baseURL+projectBase+"/"+id);
}
export const copyProject = async (id)=>{
    return await userAxios.post(baseURL+projectBase+"/"+id+"/copy");
}
export const updateProjectById = async (data,id)=>{
    return await userAxios.patch(baseURL+projectBase+"/"+id,data);
}
export const postProject = async (data)=>{
    return await userAxios.post(baseURL+projectBase,data);
}
export const fetchProjectById = async (id)=>{
    return await userAxios.get(baseURL+projectBase+"/"+id);
}
export const fetchAllVariantsByFilters = async (filter)=>{
    if(!filter) return;

    let filterString = "?";

    for(let i=0;i<Object.keys(filter)?.length;i++){
        filterString+=Object.keys(filter)[i]+"="+filter[Object.keys(filter)[i]]+"&";
    }

    return await userAxios.get(baseURL+variantBase+filterString);
}
export const fetchAllVariantsByFiltersGuest = async (filter)=>{
    if(!filter) return;

    let filterString = "?";

    for(let i=0;i<Object.keys(filter)?.length;i++){
        filterString+=Object.keys(filter)[i]+"="+filter[Object.keys(filter)[i]]+"&";
    }

    return await userAxios.get(baseURL+variantBase+"/guest"+filterString);
}
export const deleteVariant = async (id)=>{
    return await userAxios.delete(baseURL+variantBase+"/"+id);
}
export const deleteVariantGuest = async (id)=>{
    return await userAxios.delete(baseURL+variantBase+"/"+id+"/guest");
}
export const copyVariant = async (id)=>{
    return await userAxios.post(baseURL+variantBase+"/"+id+"/copy");
}
export const copyVariantGuest = async (id)=>{
    return await userAxios.post(baseURL+variantBase+"/"+id+"/copy/guest");
}
export const fetchVariantById = async (id)=>{
    return await userAxios.get(baseURL+variantBase+"/"+id);
}
export const fetchVariantByIdGuest = async (id)=>{
    return await userAxios.get(baseURL+variantBase+"/"+id+"/guest");
}
export const postVariant = async (data) =>{
    return await userAxios.post(baseURL+variantBase,data);
}
export const postVariantGuest = async (data) =>{
    return await userAxios.post(baseURL+variantBase+"/guest",data);
}
export const updateVariantById = async (data,id)=>{
    return await userAxios.patch(baseURL+variantBase+"/"+id,data);
}
export const updateVariantByIdGuest = async (data,id)=>{
    return await userAxios.patch(baseURL+variantBase+"/"+id+"/guest",data);
}
export const postEmailForProjectAccess = async (data,id)=>{
    return await userAxios.post(baseURL+projectBase+"/"+id+"/access",data);
}
export const deleteEmailForProjectAccessById = async (emailId, projectId)=>{
    return await userAxios.delete(baseURL+projectBase+"/"+projectId+"/access/"+emailId);
}
export const changeActiveStatus = async (data,id)=>{
    return await userAxios.patch(baseURL+projectBase+"/"+id+"/active",data);
}
export const changeActiveStatusVariant = async (data,id)=>{
    return await userAxios.patch(baseURL+variantBase+"/"+id+"/active",data);
}