import styles from "./index.module.css";
import TextInput from "../../elements/TextInput";
import Button from "../../elements/Button";
import H1 from "../../typography/H1";
import { useEffect, useState } from "react";
import { loginUser } from "../../../utils/apis.utils.js";
import { permTokenIdentified, tokenIdentified } from "../../../utils/constant.js";
import { redirect, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const LoginPage = (props)=>{
    const [data,setData] = useState({
        email:{
            value:"",
            error:"",
            touch:false
        },
        phone:{
            value:"",
            error:"",
            touch:false
        }
    });

    useEffect(()=>{
        localStorage.removeItem(permTokenIdentified);
        localStorage.removeItem(tokenIdentified);
    },[]);

    const navigate = useNavigate();

    const onInputValueChange = (event)=>{
        const key = event?.target?.name;

        setData(state=>{
            const newState = JSON.parse(JSON.stringify(state));


            if((key=="email" || key=="phone")&&""==event?.target?.value){
                newState[key].error=key+" is required";
            }
            else if(key=="email"&&!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event?.target?.value))){
                newState[key].error="Email is invalid";
            }
            else if(key=="phone"&&(event?.target?.value?.length!==10||isNaN(event?.target?.value))){
                newState[key].error="Phone is invalid";
            }
            else newState[key].error="";


            newState[key].value=event?.target?.value;
            newState[key].touch=true;
            return newState;
        });
    }

    const handleSubmit = async ()=>{

        const body={
            email:data?.email?.value,
            phone:data?.phone?.value
        }

        let res;
        try{
         res = await loginUser(body);
         
         localStorage.setItem("body",JSON.stringify(body));
         localStorage.setItem(tokenIdentified,res?.data?.jwt);
         
         if(res?.data?.role=="admin")
         return navigate("/otp/phone");
         else
         toast.error("Sorry your not authorized!");
        
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }


    }
    return <div className={styles["container"]}>
        <div className={styles["left"]}>
            <div>
                <div>
                    <div className={styles["logo"]}>
                    <img src={"/key2alogo.png"}/>
                    </div>
                </div>
                <div className={styles["content"]}>
                    <div className={styles["heads"]}>
                        <div className={styles["heading"]}>
                            <H1 type="heading-small-regular">Log in to your account</H1>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Email Address
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"John.williams@gmail.com"} value={data?.email?.value} error={data?.email?.error} onChange={onInputValueChange} name="email"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["line"]}>
                        <div className={styles["hr"]}></div>
                        <div>AND</div>
                        <div className={styles["hr"]}></div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Phone No
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"3767837738"} value={data?.phone?.value} error={data?.phone?.error} onChange={onInputValueChange} name="phone"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["cta"]}>
                        <Button onClick={handleSubmit} disable={!(data?.phone?.error==""&&data?.phone?.touch)||!(data?.email?.error==""&&data?.email?.touch)}>
                        Login
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles["right"]}>
            <img src={"/whyus.png"}/>
        </div>
    </div>
}

export default LoginPage;