import { useEffect, useState } from "react";
import TextInput from "../../elements/TextInput";
import Checkbox from "../../elements/Checkbox";
import H1 from "../../typography/H1";
import styles from "./index.module.css";
import Button from "../../elements/Button";
import UnderLineButton from "../../elements/UnderLineButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchUserById, registerUser, updateUserById } from "../../../utils/apis.utils";
import toast from "react-hot-toast";

const UserForm = (props)=>{
    const [data,setData] = useState({
        first_name:{
            value:"",
            error:"",
            touch:false
        },
        last_name:{
            value:"",
            error:"",
            touch:false
        },
        email:{
            value:"",
            error:"",
            touch:false
        },
        phone:{
            value:"",
            error:"",
            touch:false
        },
        isRealtor:{
            value:false
        },
        isWorkingWithRealtor:{
            value:false
        }
    });

    const [isEdit,setIsEdit] = useState(false);
    const [isMandatory,setIsMandatory] = useState(true);

    const [searchParams,setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if(searchParams.get("isEdit")=="true") setIsEdit(true);
        if(searchParams.get("isMandatory")=="false") setIsMandatory(false);
    },[]);

    useEffect(()=>{
        if(!isEdit||!searchParams.get("id")) return;

        fetchUserById(searchParams.get("id"))
        .then(res=>{
            let user = res?.data?.data;

            setData(state=>{
                const newData = JSON.parse(JSON.stringify(state));

                newData.first_name.value = user?.first_name;
                newData.last_name.value = user?.last_name;
                newData.phone.value = user?.phone;
                newData.email.value = user?.email;
                newData.isRealtor.value = user?.isRealtor;
                newData.isWorkingWithRealtor.value = user?.isWorkingWithRealtor;
                
                return newData;
            })
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[isEdit]);

    const onInputValueChange = (event)=>{
        const key = event?.target?.name;

        setData(state=>{
            const newState = JSON.parse(JSON.stringify(state));


            if((key=="email" || key=="phone" || key=="first_name" || key=="last_name")&&""==event?.target?.value&&isMandatory){
                newState[key].error=key+" is required";
            }
            else if(key=="email"&&!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event?.target?.value))){
                newState[key].error="Email is invalid";
            }
            else if(key=="phone"&&(event?.target?.value?.length!==10||isNaN(event?.target?.value))){
                newState[key].error="Phone is invalid";
            }
            else newState[key].error="";


            newState[key].value=event?.target?.value;
            newState[key].touch=true;
            return newState;
        });
    }

    const onCheckboxValueChange = (event)=>{
        const key = event?.target?.dataset?.index;

        setData(state=>{
            const newState = JSON.parse(JSON.stringify(state));

            newState[key].value = !newState[key].value;

            return newState;
        });
    }

    const onSubmit = ()=>{
        const body = {
            first_name:data?.first_name?.value,
            last_name:data?.last_name?.value,
            email:data?.email?.value,
            phone:data?.phone?.value,
            isRealtor:data?.isRealtor?.value,
            isWorkingWithRealtor:data?.isWorkingWithRealtor?.value,
            role:searchParams?.get("role")
        }
        
        if(isEdit) updateUserById(body,searchParams.get("id"))
        .then(res=>{
            toast.success("User updated succesfully!");
            return navigate(searchParams?.get("backtrace"));
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        });
        else registerUser(body)
        .then(res=>{
            toast.success("User created succesfully!");
            return navigate(searchParams?.get("backtrace"));
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    }

    const onClose = ()=>{
        return navigate(searchParams?.get("backtrace"));
    }

    return <div className={styles["container"]}>
        <div className={styles["card"]}>
            <div className={styles["content"]}>
                <div className={styles["heading"]}>
                    <div>
                        <H1 type="heading-small-regular">{(searchParams.get("isEdit")!="true")?"Add":"Update"} {(searchParams.get("role")!="imposter")?"User":"Suspect"}</H1>
                    </div>
                </div>
                <div className={styles["form"]}>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Name
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["half-input"]}>
                                <TextInput placeholder={"John"} value={data?.first_name?.value} error={data?.first_name?.error} onChange={onInputValueChange} name="first_name"/>
                            </div>
                            <div className={styles["half-input"]}>
                                <TextInput placeholder={"Williams"} value={data?.last_name?.value} error={data?.last_name?.error} onChange={onInputValueChange} name="last_name"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Email
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"John.williams@gmail.com"} value={data?.email?.value} error={data?.email?.error} onChange={onInputValueChange} name="email"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Phone Number
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"7289378938"} value={data?.phone?.value} error={data?.phone?.error} onChange={onInputValueChange} name="phone"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles["realtor-check"]}>
                        <div>
                            <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex="isRealtor" state={data?.isRealtor?.value}/>
                        </div>
                        <div className={styles["realtor-text"]}>
                            Is Realtor?
                        </div>
                </div>
                <div className={styles["realtor-check"]}>
                        <div>
                            <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex="isWorkingWithRealtor" state={data?.isWorkingWithRealtor?.value}/>
                        </div>
                        <div className={styles["realtor-text"]}>
                            With Realtor?
                        </div>
                </div>
                <div className={styles["cta"]}>
                        <div>
                            <Button onClick={onSubmit} disable={(isMandatory&&(data.first_name.value==""||data.first_name.error!==""||(!data.first_name.touch&&!isEdit)||data.last_name.value==""||data.last_name.error!==""||(!data.last_name.touch&&!isEdit)||data.email.value==""||data.email.error!==""||(!data.email.touch&&!isEdit)||data.phone.value==""||data.phone.error!==""||(!data.phone.touch&&!isEdit)))}>
                                {isEdit?"Update":"Add"}
                            </Button>
                        </div>
                        <div>
                            <div onClick={onClose}>
                                Close
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
}

export default UserForm;