import styles from "./index.module.css";
import UnderLineButton from "../../elements/UnderLineButton";
import H1 from "../../typography/H1";
import { useEffect, useState } from "react";
import { approveUser, blockUser, changeActiveStatus, copyProject, deleteProject, deleteUser, fetchAllProjectsByFilters, fetchAllUsersByFilters } from "../../../utils/apis.utils";
import { useNavigate } from "react-router-dom";
import DropDown2 from "../../elements/Dropdown2";
import Button from "../../elements/Button";
import { baseURL, permTokenIdentified, projectBase, userBase } from "../../../utils/constant";
import toast from "react-hot-toast";
import Checkbox from "../../elements/Checkbox";

const ProjectManagement = (props)=>{
    const [prospects,setProspects] = useState();
    const [refresh,setRefresh] = useState(false);
    const [steps,setSteps] = useState(["All","Realtor","User"]);
    const [selected,setSelected] = useState(0);

    const navigate = useNavigate();

    useEffect(()=>{
        fetchAllProjectsByFilters({})
        .then(res=>{
            setProspects(res?.data?.data);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[refresh,selected]);

    const toggleUser = ()=>setRefresh(state=>!state);

    const deleteProjectHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await deleteProject(id);
            toggleUser();
            toast.success("Project deleted succesfully!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    
    const updateUserHandler = (event)=>{
        let id = event?.target?.dataset?.id;

        if(!id) return toast.error("Fail - refresh and try again!");

        navigate("/project-form?isEdit=true&backtrace=/admin/dashboard/project-management&id="+id);
    }

    const addUserHandler = (event)=>{
        navigate("/project-form?isEdit=false&backtrace=/admin/dashboard/project-management");
    }

    const onTypeFilterChange = (value,idx)=>{ setSelected(idx)};

    const copyProjectHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await copyProject(id);
            toggleUser();
            toast.success("Project copied succesfully!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }

    const viewVariantHandler = async (event)=>{
        return navigate("/admin/dashboard/variant-management?backtrace=/admin/dashboard/project-management&id="+event?.target?.dataset?.id);
    }

    const onCheckboxValueChange =  async (e)=>{
        const index = e?.target?.dataset?.index;

        if(!index) return toast.error("Refresh and Try again!");

        try{
            await changeActiveStatus({isActive:!prospects[index]?.isActive},prospects[index]?._id);

            if(!prospects[index]?.isActive)
            toast.success("All project variants made active");
            else
            toast.success("All project variants made inactive");

            toggleUser();
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }   
    return <div className={styles["container"]}>
        <div className={styles["heading"]}>
            <div>
                <H1 type="heading-small">Project List</H1>
            </div>
            {window.innerWidth >850 &&<div className={styles["filters"]}>
                    <div>
                        <a href={`${baseURL}${projectBase}/export?jwt=`+localStorage.getItem(permTokenIdentified)} target="_blank" style={{textDecoration:"none",color:"inherit"}}><Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Export</Button></a>
                    </div>
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add Project</Button>
                    </div>
            </div>}
        </div>
        <div className={styles["count"]}>
            {prospects?.length!==0 && <>Showing {prospects?.length} projects</>}
        </div>
        {window.innerWidth <=850 && <div className={styles["filters"]}>
                <div>
                    <a href={`${baseURL}${projectBase}/export?jwt=`+localStorage.getItem(permTokenIdentified)} target="_blank" style={{textDecoration:"none",color:"inherit"}}><Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Export</Button></a>
                </div>
                <div>
                    <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add Project</Button>
                </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth>850 && <div className={styles["content"]}>
            <div className={styles["table"]}>
                <div className={styles["head-row"]}>
                    <div>Name</div>
                    <div>Internal Name</div>
                    <div>Variants</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    prospects?.map((prospect,index)=>{
                        return <div className={styles["rows"]}>
                        <div className={styles["row"]}>
                            <div>{prospect?.name}</div>
                            <div>{prospect?.internal_name || "-"}</div>
                            <div>{prospect?.variants?.length}</div>
                            <div>
                                <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex={index} state={prospect?.isActive}/>
                            </div>
                            <div>
                                <UnderLineButton onClick={viewVariantHandler} id={prospect?._id}>
                                    variants
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProjectHandler} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={copyProjectHandler} id={prospect?._id}>
                                    copy
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth<=850 && <div className={styles["content"]}>
            <div className={styles["table"]}>
                {
                    prospects?.map((prospect,index)=>{
                        return <div className={styles["mobile-rows"]}>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Name</div>
                                <div>{prospect?.name}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Variants</div>
                                <div>{prospect?.variants?.length}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Internal Name</div>
                                <div>{prospect?.internal_name || "-"}</div>
                            </div>
                            <div>
                                <div>
                                    <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex={index} state={prospect?.isActive}/>
                                </div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={viewVariantHandler} id={prospect?._id}>
                                    variants
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={deleteProjectHandler} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={copyProjectHandler} id={prospect?._id}>
                                    copy
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {
            prospects?.length==0 && <div className={styles["no-one"]}>
                No projects found!
            </div>

        }
    </div>
}
export default ProjectManagement;