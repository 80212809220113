import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { BsBuilding, BsExclamationOctagonFill, BsPersonFill, BsUnlockFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { permTokenIdentified } from "../../../utils/constant";

const Sidemenu = (props)=>{
    const [expand,setExpand] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const onLogoutHandler = ()=>{
        localStorage.removeItem(permTokenIdentified);
        return navigate("/");
    }

    const toggleExpand = ()=> {props?.toggleExpand(); setExpand(state=>!state);}

    return <div className={expand?styles["container"]:`${styles["container"]} ${styles["collapse"]}`}>
        <div className={styles["content"]}>
            <div className={styles["top"]} onClick={toggleExpand}>
                <div>
                    <img src="/key2alogo.png" alt="logo"/>
                </div>
                <div>
                    <div className={styles["hamburger"]}></div>
                </div>
            </div>
            <div className={styles["middle"]}>
                <div className={styles["nav"]}>
                    <Link to={"/admin/dashboard/request-access"}>
                        <div className={location.pathname=="/admin/dashboard/request-access"?`${styles["nav-item"]} ${styles["active"]}`:styles["nav-item"]}>
                            <div>
                                <BsUnlockFill/>
                            </div>
                            <div>
                                Requested Access
                            </div>
                        </div>
                    </Link>
                    <Link to={"/admin/dashboard/user-management"}>
                        <div className={location.pathname=="/admin/dashboard/user-management"?`${styles["nav-item"]} ${styles["active"]}`:styles["nav-item"]}>
                            <div>
                                <BsPersonFill/>
                            </div>
                            <div>
                                User Management
                            </div>
                        </div>
                    </Link>
                    <Link to={"/admin/dashboard/black-list-management"}>
                        <div className={location.pathname=="/admin/dashboard/black-list-management"?`${styles["nav-item"]} ${styles["active"]}`:styles["nav-item"]}>
                            <div>
                                <BsExclamationOctagonFill/>
                            </div>
                            <div>
                                Black List Management
                            </div>
                        </div>
                    </Link>
                    <Link to={"/admin/dashboard/project-management"}>
                        <div className={location.pathname=="/admin/dashboard/project-management"?`${styles["nav-item"]} ${styles["active"]}`:styles["nav-item"]}>
                            <div>
                                <BsBuilding/>
                            </div>
                            <div>
                                Project Management
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={styles["bottom"]}>
                <div className={styles["cta"]} onClick={onLogoutHandler}>
                    Logout
                </div>
            </div>
        </div>
    </div>
}
export default Sidemenu;