import { Outlet, Route, Routes } from "react-router-dom";
import styles from "./index.module.css";
import Sidemenu from "../../elements/Sidemenu";
import { useState } from "react";

const Dashboard = (props)=>{
    const [expand,setExpand] = useState(false);

    const toggleExpand = ()=>setExpand(state=>!state);

    return <div className={expand?styles["container"]:`${styles["container"]} ${styles["collapse"]}`}>
        <div className={styles["sidemenu"]}>
            <Sidemenu toggleExpand={toggleExpand}/>
        </div>
        <div className={styles["content"]}>
            <Outlet/>
        </div>
    </div>
}

export default Dashboard;