import { useEffect, useState } from "react";
import TextInput from "../../elements/TextInput";
import Checkbox from "../../elements/Checkbox";
import H1 from "../../typography/H1";
import styles from "./index.module.css";
import Button from "../../elements/Button";
import UnderLineButton from "../../elements/UnderLineButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchProjectById, fetchUserById, postProject, registerUser, updateProjectById, updateUserById } from "../../../utils/apis.utils";
import DropDown2 from "../../elements/Dropdown2";
import toast from "react-hot-toast";

const ProjectForm = (props)=>{
    const [data,setData] = useState({
        name:{
            value:"",
            error:"",
            touch:false
        },
        address:{
            value:"",
            error:"",
            touch:false
        },
        internal_name:{
            value:"",
            error:"",
            touch:false
        },
    });

    const [steps,setSteps] = useState(["Toronto GTA ON","Edmonton AB","Calgary AB","Airdie AB","Chestermere AB","Cochrane AB","Fort Saskatchewan AB","St Albert AB","Ottawa ON","London ON"]);
    const [selected,setSelected] = useState(0);

    const [isEdit,setIsEdit] = useState(false);
    const [isMandatory,setIsMandatory] = useState(true);

    const [searchParams,setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if(searchParams.get("isEdit")=="true") setIsEdit(true);
        if(searchParams.get("isMandatory")=="false") setIsMandatory(false);
    },[]);

    useEffect(()=>{
        if(!isEdit||!searchParams.get("id")) return;

        fetchProjectById(searchParams.get("id"))
        .then(res=>{
            let project = res?.data?.data;

            setData(state=>{
                const newData = JSON.parse(JSON.stringify(state));

                newData.name.value = project?.name;
                newData.internal_name.value = project?.internal_name;
                newData.address.value = project?.address;
                
                return newData;
            });

            let selected = steps?.findIndex(el=>el==project?.location);

            if(selected!=-1)
            setSelected(selected);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[isEdit]);

    const onInputValueChange = (event)=>{
        const key = event?.target?.name;

        setData(state=>{
            const newState = JSON.parse(JSON.stringify(state));


            if((key=="name" || key=="address" || key=="internal_name")&&""==event?.target?.value&&isMandatory){
                newState[key].error=key+" is required";
            }
            else newState[key].error="";


            newState[key].value=event?.target?.value;
            newState[key].touch=true;
            return newState;
        });
    }

    const onSubmit = ()=>{
        const body = {
            name:data?.name?.value,
            address:data?.address?.value,
            location:steps[selected],
            internal_name:data?.internal_name?.value
        }
        
        if(isEdit) updateProjectById(body,searchParams.get("id"))
        .then(res=>{
            toast.success("Project updated successfully!");
            return navigate(searchParams?.get("backtrace"));
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        });
        else postProject(body)
        .then(res=>{
            toast.success("Project created succesfully!");
            return navigate(searchParams?.get("backtrace"));
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    }

    const onClose = ()=>{
        return navigate(searchParams?.get("backtrace"));
    }

    const onLocationFilterChange = (value,idx)=>{ setSelected(idx)};

    return <div className={styles["container"]}>
        <div className={styles["card"]}>
            <div className={styles["content"]}>
                <div className={styles["heading"]}>
                    <div>
                        <H1 type="heading-small-regular">{isEdit?"Update":"Add"} Project</H1>
                    </div>
                </div>
                <div className={styles["form"]}>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Name
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"Peak 34"} value={data?.name?.value} error={data?.name?.error} onChange={onInputValueChange} name="name"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Internal Name
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"Peak 34"} value={data?.internal_name?.value} error={data?.internal_name?.error} onChange={onInputValueChange} name="internal_name"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Location
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <DropDown2 onChange={onLocationFilterChange} steps={steps} selectedValue={selected} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Address
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <textarea placeholder={"13814 Lakewood Crossing Blvd, Calgary, Alb 273878"} value={data?.address?.value} error={data?.address?.error} onChange={onInputValueChange} name="address"/>
                                <div  className={styles["error"]}>{data?.address?.error}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles["cta"]}>
                        <div>
                            <Button onClick={onSubmit} disable={(data.name.value==""||data.name.error!==""||(!data.name.touch&&!isEdit)||data.address.value==""||data.address.error!==""||(!data.address.touch&&!isEdit))}>
                                {isEdit?"Update":"Add"}
                            </Button>
                        </div>
                        <div>
                            <div onClick={onClose}>
                                Close
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
}

export default ProjectForm;