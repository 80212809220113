import styles from "./index.module.css";
import UnderLineButton from "../../elements/UnderLineButton";
import H1 from "../../typography/H1";
import { useEffect, useState } from "react";
import { approveUser, blockUser, deleteUser, fetchAllUsersByFilters } from "../../../utils/apis.utils";
import { useNavigate } from "react-router-dom";
import DropDown2 from "../../elements/Dropdown2";
import Button from "../../elements/Button";
import { baseURL, permTokenIdentified, userBase } from "../../../utils/constant";
import toast from "react-hot-toast";

const UserManagement = (props)=>{
    const [prospects,setProspects] = useState();
    const [refresh,setRefresh] = useState(false);
    const [steps,setSteps] = useState(["All","Realtor","User"]);
    const [selected,setSelected] = useState(0);

    const navigate = useNavigate();

    useEffect(()=>{
        let body={role:"user"};

        if(selected==1) body.isRealtor=true;
        else if(selected==2) body.isRealtor = false;

        fetchAllUsersByFilters(body)
        .then(res=>{
            setProspects(res?.data?.data);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[refresh,selected]);

    const toggleUser = ()=>setRefresh(state=>!state);

    const deleteProspect = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await deleteUser(id);
            toggleUser();
            toast.success("User deleted succesfully!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }

    const blockUserHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await blockUser(id);
            toggleUser();
            toast.success("User blocked succesfully!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    
    const updateUserHandler = (event)=>{
        let id = event?.target?.dataset?.id;

        if(!id) return toast.error("Failed - Refresh and try again!");

        navigate("/user-form?isEdit=true&isMandatory=true&backtrace=/admin/dashboard/user-management&role=user&id="+id);
    }

    const addUserHandler = (event)=>{
        navigate("/user-form?isEdit=false&isMandatory=true&backtrace=/admin/dashboard/user-management&role=user");
    }

    const onTypeFilterChange = (value,idx)=>{ setSelected(idx)};

    return <div className={styles["container"]}>
        <div className={styles["heading"]}>
            <div>
                <H1 type="heading-small">User List</H1>
            </div>
            {window.innerWidth>= 850 && <div className={styles["filters"]}>
                    <div>
                        <DropDown2 steps={steps} selectedValue={selected} onChange={onTypeFilterChange}/>
                    </div>
                    <div>
                        <a href={`${baseURL}${userBase}/export?role=user&jwt=`+localStorage.getItem(permTokenIdentified)} target="_blank" style={{textDecoration:"none",color:"inherit"}}><Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Export</Button></a>
                    </div>
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add User</Button>
                    </div>
            </div>}
        </div>
        <div className={styles["count"]}>
            {prospects?.length!==0 && <>Showing {prospects?.length} users</>}
        </div>
        {window.innerWidth< 850 && <div className={styles["filters"]}>
                    <div>
                        <DropDown2 steps={steps} selectedValue={selected} onChange={onTypeFilterChange}/>
                    </div>
                    <div>
                        <a href={`${baseURL}${userBase}/export?role=user&jwt=`+localStorage.getItem(permTokenIdentified)} target="_blank" style={{textDecoration:"none",color:"inherit"}}><Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Export</Button></a>
                    </div>
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add User</Button>
                    </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth>850 && <div className={styles["content"]}>
            <div className={styles["table"]}>
                <div className={styles["head-row"]}>
                    <div>Name</div>
                    <div>Phone Number</div>
                    <div>Email Address</div>
                    <div>Is Realtor</div>
                    <div>With Realtor</div>
                    <div></div>
                    <div></div>
                </div>
                {
                    prospects?.map(prospect=>{
                        return <div className={styles["rows"]}>
                        <div className={styles["row"]}>
                            <div>{prospect?.first_name} {prospect?.last_name}</div>
                            <div>{prospect?.phone}</div>
                            <div>{prospect?.email}</div>
                            <div>{prospect?.isRealtor?"Yes":"No"}</div>
                            <div>{prospect?.isWorkingWithRealtor?"Yes":"No"}</div>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={blockUserHandler} id={prospect?._id}>
                                    block
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProspect} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth<=850 && <div className={styles["content"]}>
            <div className={styles["table"]}>
                {
                    prospects?.map(prospect=>{
                        return <div className={styles["mobile-rows"]}>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Name</div>
                                <div>{prospect?.first_name} {prospect?.last_name}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Phone</div>
                                <div>{prospect?.phone}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Email</div>
                                <div>{prospect?.email}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>isRealtor</div>
                                <div>{prospect?.isRealtor?"Yes":"No"}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>withRealtor</div>
                                <div>{prospect?.isWorkingWithRealtor?"Yes":"No"}</div>
                            </div>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={blockUserHandler} id={prospect?._id}>
                                    block
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProspect} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {
            prospects?.length==0 && <div className={styles["no-one"]}>
                No users found!
            </div>

        }
    </div>
}
export default UserManagement;