import styles from "./index.module.css";
import { useEffect, useState } from "react";

const DropDown2 = ({steps,selectedValue,onChange,selectedStyle,stepStyle,name})=>{
    const [isOpen,setIsOpen] = useState(true);
    const [selected,setSelected] = useState(null);

    useEffect(()=>{
        setSelected(selectedValue);
    },[selectedValue]);

    const onToggle = ()=>setIsOpen(state=>!state);
    const onValueSelect = (e)=>{
        if(e?.target?.dataset?.value&&e?.target?.dataset?.index){
            onChange(e?.target?.dataset?.value,e?.target?.dataset?.index,name);
            setSelected(e?.target?.dataset?.index);
            onToggle();
        }
    }

    return <div className={styles["container"]}>
        <div className={styles["selected"]} style={selectedStyle}>
            <div>
                {steps[selected]}
            </div>
            <div>
                <div className={styles["down"]} onClick={onToggle} >
                    <img src={"/down.png"}  fill={true} objectFit="contain"/>
                </div>
            </div>
        </div>
        <div className={isOpen?`${styles["dropdown"]} ${styles["collapse"]}`:styles["dropdown"]}>
            {steps?.map((step,idx)=><div className={styles["dropdown-item"]} data-value={step} data-index={idx} onClick={onValueSelect} style={stepStyle}>
                {step}
            </div>)}
        </div>
    </div>
}

export default DropDown2;