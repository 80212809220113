"use client"
import { useRef, useState } from "react";
import styles from "./index.module.css";

const OTPInput = ({onChange})=>{

    const [otp,setOtp] = useState(["","","","","",""]);
    const [refs,setRefs] = useState([useRef(),useRef(),useRef(),useRef(),useRef(),useRef()])

    const otpInputChange = (event)=>{
        let index = event?.target?.dataset?.index;
        let value = event?.target?.value;

        if(!(isNaN(value)||value?.length > 1)){
            setOtp(state=>{
                let arr = [...state];
                arr[index] = value;

                if(onChange) onChange(arr.join(""));
                return arr;
            })
        } 
        else return;

        if(index!==5&&value?.length==1){
            refs[parseInt(index)+1]?.current?.focus();
        }
        else if(value?.length==1) refs[0]?.current?.focus();
    }

    return <div className={styles["container"]}>
        <div className={styles["otp"]}>
            <input type="text" placeholder="0" data-index="0" onChange={otpInputChange} ref={refs[0]} value={otp[0]}/>
        </div>
        <div className={styles["otp"]}>
            <input type="text" placeholder="0" data-index="1" onChange={otpInputChange} ref={refs[1]} value={otp[1]}/>
        </div>
        <div className={styles["otp"]}>
            <input type="text" placeholder="0" data-index="2" onChange={otpInputChange} ref={refs[2]} value={otp[2]}/>
        </div>
        <div className={styles["otp"]}>
            <input type="text" placeholder="0" data-index="3" onChange={otpInputChange} ref={refs[3]} value={otp[3]}/>
        </div>
        <div className={styles["otp"]}>
            <input type="text" placeholder="0" data-index="4" onChange={otpInputChange} ref={refs[4]} value={otp[4]}/>
        </div>
        <div className={styles["otp"]}>
            <input type="text" placeholder="0" data-index="5" onChange={otpInputChange} ref={refs[5]} value={otp[5]}/>
        </div>
    </div>
}

export default OTPInput;