import styles from "./index.module.css";
import Button from "../../elements/Button";
import H1 from "../../typography/H1";
import UnderLineButton from "../../elements/UnderLineButton";
import OTPInput from "../../elements/OTPInput";
import { useEffect, useState } from "react";
import { permTokenIdentified, tokenIdentified } from "../../../utils/constant.js";
import { requestSmsOTP, verifySmsOTP } from "../../../utils/apis.utils.js";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const VerifyEmailOtp = (props)=>{
    const [otp,setOTP] = useState('');
    const [disable,setDisable] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        const data = localStorage.getItem("body");
        const register = localStorage.getItem("register");

        if(!data) return navigate("/login");

        requestSmsOTP()
        .then(
            res=>{
                toast.success(res?.data?.message);
                setDisable(true);

                setTimeout(()=>{
                    setDisable(false);
                },30000);
            }
        )
        .catch(
            err=>{
                if(err?.response?.data?.message)
                toast.error(err?.response?.data?.message);
                else 
                toast.error("Something went wrong!");
            }
        )

    },[]);

    const onOTPChange = (otp)=>setOTP(otp);

    const onSubmit = ()=>{
        if(otp?.length!==6) return toast.error("invalid otp!");

        verifySmsOTP({otp,attempt:"login"})
        .then(res=>{
                if(res?.data?.jwt)
                {
                    toast.success(res?.data?.message);
                    localStorage.setItem(permTokenIdentified,res?.data?.jwt);
                    return navigate("/admin/dashboard/request-access");
                }
                else return navigate("/");
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        });
    }

    const handleResendOTP = async ()=>{
        const data = localStorage.getItem("body");
        const register = localStorage.getItem("register");

        if(!data){
            toast.error("Suspicious activity detacted, Try again!");
            return navigate("/login");
        }

        requestSmsOTP()
        .then(
            res=>{
                toast.success(res?.data?.message);
                setDisable(true);

                setTimeout(()=>{
                    setDisable(false);
                },30000);
            }
        )
        .catch(
            err=>{
                if(err?.response?.data?.message)
                toast.error(err?.response?.data?.message);
                else 
                toast.error("Something went wrong!");
            }
        )
    }

    return <div className={styles["container"]}>
        <div className={styles["left"]}>
            <div>
                <div>
                    <div className={styles["logo"]}>
                        <img src={"/key2alogo.png"} fill={true} objectFit="contain"/>
                    </div>
                </div>
                <div className={styles["content"]}>
                    <div className={styles["heads"]}>

                        <div className={styles["heading"]}>
                            <H1 type="heading-small-regular">Verify Sms Otp</H1>
                        </div>
                        <div className={styles["tagline"]}>
                            Please ask Yogi Bhanushali for OTP
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <OTPInput onChange={onOTPChange}/>
                    </div>
                    <div className={styles["cta"]}>
                        <div>
                            <Button onClick={onSubmit}>
                                Verify
                            </Button>
                        </div>
                        <div>
                            {!disable && <UnderLineButton disable={disable} marginTop="calc(.2 * var(--base-y))"  onClick={handleResendOTP}>
                                Resend
                            </UnderLineButton>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles["right"]}>
            <img src={"/buyerguide.png"} fill="true" objectFit="cover"/>
        </div>
    </div>
}

export default VerifyEmailOtp;