import axios from 'axios';
import { baseURL, permTokenIdentified, tokenIdentified } from '../constant.js';

// Create an instance of axios
const userAxios = axios.create({
  // Set the base URL if needed
  baseURL: baseURL,
});

// Add a request interceptor to attach the JWT token to each request
userAxios.interceptors.request.use(
  (config) => {
    // Get the JWT token from localStorage
    let token;

    if(localStorage?.getItem(permTokenIdentified))
    token = localStorage.getItem(permTokenIdentified);
    else 
    token = localStorage.getItem(tokenIdentified);

    // If the token exists, set it in the request header
    if (token) {
      config.headers.jwt = `${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

export default userAxios;
