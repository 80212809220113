export const ACCESS_TOKEN = "demoa1b2c3";
export const locationEnum = ["Toronto GTA ON","Edmonton AB","Calgary AB","Airdie AB","Chestermere AB","Cochrane AB","Fort Saskatchewan AB","St Albert AB","Ottawa ON","London ON"];
export const typeEnum = ["condo","townhome","detached","semi-detached"];
export const sortTypeEnum = ["","asc","dsc"];
export const sortCriteriaEnum = ["","ppsf","price","sqft"];
export const iconMapping ={
    school:"/school.png",
    hospital:"/hospital.png",
    park:"/park.png",
    downtown:"/downtown.png",
    doubleParking:"/double-parking.png",
    park:"/park.png",
}

export const tokenIdentified = "blaship12a";
export const permTokenIdentified = "bluesting9287a";

export const baseURL = "https://personalwebsiteapi.onrender.com/api/v1";
export const variantBase = "/variant";
export const userBase = "/user";
export const projectBase = "/project";