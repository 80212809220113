import { useEffect, useRef, useState } from "react";
import TextInput from "../../elements/TextInput";
import Checkbox from "../../elements/Checkbox";
import H1 from "../../typography/H1";
import styles from "./index.module.css";
import Button from "../../elements/Button";
import UnderLineButton from "../../elements/UnderLineButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchAllVariantsByFilters, fetchProjectById, fetchUserById, fetchVariantById, postProject, postVariant, registerUser, updateProjectById, updateUserById, updateVariantById } from "../../../utils/apis.utils";
import DropDown2 from "../../elements/Dropdown2";
import { GoPlusCircle } from "react-icons/go";
import { BsDownload } from "react-icons/bs";
import { baseURL, typeEnum, variantBase } from "../../../utils/constant";
import toast from "react-hot-toast";

const VariantForm = (props)=>{
    const [data,setData] = useState({
        images:[],
        image_alt:[],
        image_object_ids:[],
        beds:{
            value:"",
            error:"",
            touch:false
        },
        baths:{
            value:"",
            error:"",
            touch:false
        },
        price:{
            value:"",
            error:"",
            touch:false
        },
        includesGST:{
            value:false
        },
        sqft:{
            value:"",
            error:"",
            touch:false
        },
        highlights:[
            {
                text:{
                    value:"",
                    error:"",
                    touch:false
                },
                icon:0,
            },
            {
                text:{
                    value:"",
                    error:"",
                    touch:false
                },
                icon:0,
            },
            {
                text:{
                    value:"",
                    error:"",
                    touch:false
                },
                icon:0,
            },
            {
                text:{
                    value:"",
                    error:"",
                    touch:false
                },
                icon:0,
            }
        ],
        description:{
            value:"",
            error:"",
            touch:false
        },
        ammenities:[
            {
                text:{
                    value:"",
                    error:"",
                    touch:false
                },
                icon:0,
            }
        ],
        virtual_tour_link:{
            value:"",
            error:"",
            touch:false
        },
        youtube_link:{
            value:"",
            error:"",
            touch:false
        },
        documents:[],
        document_strings:[],
        sections:[],
        accurate_location:[{
            value:"",
            error:"",
            touch:false
        },
        {
            value:"",
            error:"",
            touch:false
        }],
        generic_location:[{
            value:"",
            error:"",
            touch:false
        },
        {
            value:"",
            error:"",
            touch:false
        }],
        isRecommended:{
            value:false
        },
        priorityNo:{
            value:"",
            error:"",
            touch:false
        },
        meta:{
            title:{
                value:"",
                error:"",
                touch:false
            },
            keywords:{
                value:"",
                error:"",
                touch:false
            },
            description:{
                value:"",
                error:"",
                touch:false
            },
        },
        type:0,
        occupancy_date:{
            value:null
        },
        recommended:[0]
    });

    const [variantStrings,setVariantStrings] = useState([]);
    const [variantIds,setVariantIds] = useState([]);
    
    const [steps,setSteps] = useState([
        <img src="/hospital.png" alt="hospital"/>,
        <img src="/park.png" alt="park"/>,
        <img src="/downtown.png" alt="downtown"/>,
        <img src="/double-parking.png" alt="double-parking"/>,
        <img src="/free-basement.png" alt="free-basement"/>,
        <img src="/ac.png" alt="ac"/>,
        <img src="/airport.png" alt="airport"/>,
        <img src="/atm.png" alt="atm"/>,
        <img src="/balcony.png" alt="balcony"/>,
        <img src="/bank.png" alt="bank"/>,
        <img src="/bus.png" alt="bus"/>,
        <img src="/car.png" alt="car"/>,
        <img src="/cart.png" alt="cart"/>,
        <img src="/cinema.png" alt="cinema"/>,
        <img src="/elevator.png" alt="elevator"/>,
        <img src="/energy efficiency.png" alt="energy efficiency"/>,
        <img src="/fireplace.png" alt="fireplace"/>,
        <img src="/furnished.png" alt="furnished"/>,
        <img src="/garage.png" alt="garage"/>,
        <img src="/garbage.png" alt="garbage"/>,
        <img src="/garden.png" alt="garden"/>,
        <img src="/grocery.png" alt="grocery"/>,
        <img src="/gym.png" alt="gym"/>,
        <img src="/heating.png" alt="heating"/>,
        <img src="/laundary.png" alt="laundary"/>,
        <img src="/library.png" alt="library"/>,
        <img src="/pet friendly.png" alt="pet friendly"/>,
        <img src="/restaurant.png" alt="restaurant"/>,
        <img src="/security.png" alt="security"/>,
        <img src="/swimming pool.png" alt="swimming pool"/>,
        <img src="/train.png" alt="train"/>,
        <img src="/wheelchair.png" alt="wheelchair"/>,
        <img src="/wifi.png" alt="wifi"/>,
    ]);
    const [selected,setSelected] = useState(0);

    const [isEdit,setIsEdit] = useState(false);
    const [isMandatory,setIsMandatory] = useState(true);

    const fileRef = useRef();

    const [searchParams,setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if(searchParams.get("isEdit")=="true") setIsEdit(true);
        if(searchParams.get("isMandatory")=="false") setIsMandatory(false);
    },[]);

    useEffect(()=>{
        if(variantIds?.length == 0||!data?.tempRecommendation) return;

        let newData = cloneData(data);

        newData.recommended =[];

        for(let i=0;i<newData?.tempRecommendation?.length;i++){
            let index = variantIds?.findIndex(el=>el==newData?.tempRecommendation[i]);

            if(index==-1) continue;

            newData?.recommended?.push(index);
        }

        setData(newData);

    },[variantIds]);

    useEffect(()=>{

        fetchAllVariantsByFilters({})
        .then(res=>{
            const data = res?.data?.data;

            let newVariantStrings = [];
            let variantIds = [];

            for(let i=0;i<data?.length;i++){
                newVariantStrings?.push(data[i]?.project?.name+"-"+data[i]?.beds+"-beds-"+data[i]?.baths+"-baths");
                variantIds.push(data[i]?._id);
            }

            console.log("strings:",newVariantStrings);
            console.log("ids:",variantIds);

            setVariantStrings(newVariantStrings);
            setVariantIds(variantIds);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        });

        if(!isEdit||!searchParams.get("id")) return;

        fetchVariantById(searchParams.get("id"))
        .then(res=>{
            let variant = res?.data?.data;

            setData(state=>{
                const newData = cloneData((state));

                for(let i=0;i<variant?.images?.length;i++){
                    newData.images?.push(variant?.images[i]?.url);
                    newData.image_object_ids?.push(variant?.images[i]?._id);
                    newData.image_alt?.push({value:variant?.images[i]?.alt,error:"",touch:false});
                }

                for(let i=0;i<variant?.documents?.length;i++){
                    newData.documents?.push({name:variant?.documents[i]});
                    newData.document_strings?.push(variant?.documents[i]);
                }

                newData.highlights = [];
                for(let i=0;i<variant?.highlights?.length;i++){
                    newData.highlights.push({
                        icon:variant?.highlights[i]?.icon,
                        text:{
                            value:variant?.highlights[i]?.text,
                            error:"",
                            touch:false
                        }
                    })
                }

                newData.ammenities = [];
                for(let i=0;i<variant?.ammenities?.length;i++){
                    newData.ammenities.push({
                        icon:variant?.ammenities[i]?.icon,
                        text:{
                            value:variant?.ammenities[i]?.text,
                            error:"",
                            touch:false
                        }
                    })
                }

                for(let i=0;i<variant?.sections?.length;i++){
                    newData.sections.push({
                        heading:{
                            value:variant?.sections[i]?.heading,
                            error:"",
                            touch:false
                        },
                        data:[]
                    });

                    for(let j=0;j<variant?.sections[i]?.data?.length;j++){
                        let obj ={};

                        obj.heading ={
                            value:variant?.sections[i]?.data[j]?.heading,
                            error:"",
                            touch:false
                        }
                        obj.data=[];

                        for(let k=0;k<variant?.sections[i]?.data[j]?.data.length;k++){
                            obj.data.push({
                                value:variant?.sections[i]?.data[j]?.data[k],
                                error:"",
                                touch:false
                            })
                        }

                        newData.sections[i].data.push(obj);

                    }
                }

                newData.type = typeEnum.findIndex(el=>el==variant?.type); 
                newData.beds.value = variant?.beds+"";
                newData.baths.value = variant?.baths+"";
                newData.price.value = variant?.price+"";
                newData.isRecommended.value = variant?.isRecommended;
                newData.includesGST.value = variant?.includesGST;
                newData.sqft.value = variant?.sqft+"";
                newData.description.value = variant?.description+"";
                newData.virtual_tour_link.value = variant?.virtual_tour_link+"";
                newData.youtube_link.value = variant?.youtube_link+"";
                newData.accurate_location[0].value = variant?.accurate_location?.coordinates[0]+"";
                newData.accurate_location[1].value = variant?.accurate_location?.coordinates[1]+"";
                newData.generic_location[0].value = variant?.generic_location?.coordinates[0]+"";
                newData.generic_location[1].value = variant?.generic_location?.coordinates[1]+"";
                newData.priorityNo.value = variant?.priorityNo+"";
                newData.meta.title.value = variant?.meta?.title;
                newData.meta.description.value = variant?.meta?.description;
                newData.meta.keywords.value = variant?.meta?.keywords;
                newData.occupancy_date.value = variant?.occupancy_date?.split("T")[0];
                newData.tempRecommendation = variant?.recommended;

                return newData;
            });
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[isEdit]);

    const onInputValueChange = (event)=>{
        let key = event?.target?.name;

        setData(state=>{
            const newState = cloneData(state);


            if(""==event?.target?.value&&isMandatory){
                if(!key.includes("[")&&!key.includes("meta"))
                {   
                    newState[key].error=key+" is required";
                }
                else{
                    if(key?.split("[")[0]=="highlights" || key?.split("[")[0]=="ammenities") {
                        newState[key?.split("[")[0]][key?.split("[")[1][0]].text.error=key?.split("[")[0]+" is required!";
                    }
                    else if(key?.includes("location")){
                        newState[key?.split(["["])[0]][key?.split("[")[1][0]].error=key?.split("[")[0]+" is required!";
                    }
                    else if(key?.includes("meta")){
                        newState[key?.split("_")[0]][key?.split("_")[1]].error=key?.split("_")[1]+" is required!";
                    }
                    else {
                        newState[key?.split("[")[0]][key?.split("[")[1][0]].error=key?.split("[")[0]+ " is required!";
                    }
                } 
                
            }
            else {
                
                if(!key.includes("[")&&!key.includes("meta")) newState[key].error="";
                else{
                    if(key?.split("[")[0]=="highlights" || key?.split("[")[0]=="ammenities") {
                        newState[key?.split("[")[0]][key?.split("[")[1][0]].text.error="";
                    }
                    else if(key?.includes("location")){
                        newState[key?.split(["["])[0]][key?.split("[")[1][0]].error="";
                    }
                    else if(key?.includes("meta")){
                        newState[key?.split("_")[0]][key?.split("_")[1]].error="";
                    }
                    else {
                        newState[key?.split("[")[0]][key?.split("[")[1][0]].error="";
                    }
                }
            }

            if(!key.includes("[")&&!key.includes("meta")){
                newState[key].value=event?.target?.value;
                newState[key].touch=true;
            }
            else{
                if(key?.split("[")[0]=="highlights" || key?.split("[")[0]=="ammenities"){
                    newState[key?.split("[")[0]][key?.split("[")[1][0]].text.value=event?.target?.value;
                    newState[key?.split("[")[0]][key?.split("[")[1][0]].text.touch=true;
                }
                else if(key?.includes("location")){
                    newState[key?.split(["["])[0]][key?.split("[")[1][0]].value=event?.target?.value;
                    newState[key?.split(["["])[0]][key?.split("[")[1][0]].touch=true;
                }
                else if(key?.includes("meta")){
                    newState[key?.split("_")[0]][key?.split("_")[1]].value=event?.target?.value;
                    newState[key?.split("_")[0]][key?.split("_")[1]].touch=true;
                }
                else{
                    newState[key?.split("[")[0]][key?.split("[")[1][0]].value=event?.target?.value;
                    newState[key?.split("[")[0]][key?.split("[")[1][0]].touch=true;
                }
                
            }

            return newState;
        });
    }

    const onSubmit = ()=>{
        const body = new FormData();

        for(let i=0;i<data?.images?.length;i++){
            body.append('images',data.images[i]);

            if(data?.images?.length==1)
            body.append('image_alt[0]',data.image_alt[i]?.value);
            else
            body.append('image_alt',data.image_alt[i]?.value);

            body.append('image_object_ids['+i+']',data.image_object_ids[i]);
        }

        for(let i=0;i<data?.highlights?.length;i++){
            body.append('highlights['+i+'][icon]',data?.highlights[i]["icon"]);
            body.append('highlights['+i+'][text]',data?.highlights[i]["text"]?.value);
        }
        
        body.append('beds',data?.beds?.value);
        body.append('baths',data?.baths?.value);
        body.append('price',data?.price?.value);
        body.append('includesGST',data?.includesGST?.value);
        body.append('sqft',data?.sqft?.value);
        body.append('description',data?.description?.value);

        for(let i=0;i<data?.ammenities?.length;i++){
            body.append('ammenities['+i+'][icon]',data?.ammenities[i]["icon"]);
            body.append('ammenities['+i+'][text]',data?.ammenities[i]["text"]?.value);
        }

        body.append('virtual_tour_link',data?.virtual_tour_link?.value);
        body.append('youtube_link',data?.youtube_link?.value);

        for(let i=0;i<data?.documents?.length;i++){
            body.append('documents',data.documents[i]);
            body.append('document_strings['+i+']',data.document_strings[i]);
        }

        for(let i=0;i<data?.sections?.length;i++){
            body.append('sections['+i+"][heading]",data.sections[i]?.heading?.value);
            for(let j=0;j<data?.sections[i]?.data?.length;j++){    
                body.append('sections['+i+"]["+j+"][heading]",data.sections[i]?.data[j]?.heading?.value);
                for(let z=0;z<data?.sections[i]?.data?.length;z++){    
                    body.append('sections['+i+"]["+j+"]["+z+"]",data.sections[i]?.data[j]?.data[z]?.value);
                }
            }
        }

        body.append('accurate_location[0]',data.accurate_location[0]?.value);
        body.append('accurate_location[1]',data.accurate_location[1]?.value);
        body.append('generic_location[0]',data.generic_location[0]?.value);
        body.append('generic_location[1]',data.generic_location[1]?.value);
        body.append('isRecommended',data?.isRecommended?.value);
        body.append('priorityNo',data?.priorityNo?.value);
        body.append('meta[title]',data?.meta?.title?.value);
        body.append('meta[description]',data?.meta?.description?.value);
        body.append('meta[keywords]',data?.meta?.keywords?.value);
        body.append('type',typeEnum[data?.type]);
        body.append('occupancy_date',data?.occupancy_date?.value);
        body.append('project',searchParams?.get("projectId"));

        for(let i=0;i<data?.recommended?.length;i++){
            body.append('recommended',variantIds[data?.recommended[i]]);
        }
        if(isEdit) updateVariantById(body,searchParams.get("id"))
        .then(res=>{
            toast.success("Variant updated succesfully!")
            return navigate(searchParams?.get("backtrace"));
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        });
        else postVariant(body)
        .then(res=>{
            toast.success("Variant created succesfully!")
            return navigate(searchParams?.get("backtrace"));
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    }

    const onClose = ()=>{
        return navigate(searchParams?.get("backtrace"));
    }

    const onLocationFilterChange = (value,idx,name)=>{ 
        if(!name) return;

        setSelected(idx)

        const newData = cloneData(data);

        newData[name?.split("[")[0]][name?.split("[")[1]?.split("]")[0]].icon = parseInt(idx);

        setData(newData)
    };

    const onTypeFilterChange = (value,idx,name)=>{ 
        if(!name) return;

        setSelected(idx)

        const newData = cloneData(data);

        newData.type = parseInt(idx);

        setData(newData)
    };

    const onRecommendedFilterChange = (value,idx,name)=>{ 
        if(!name) return;

        const newData = cloneData(data);

        let index = name?.split("[")[1][0];
        
        console.log("index",index);

        if(!index || isNaN(index)) return;
        else index = parseInt(index);

        newData.recommended[index] = parseInt(idx);

        setData(newData)
    };

    const onCheckboxValueChange = (event)=>{
        const key = event?.target?.dataset?.index;
        const newState = cloneData(data);
        if(typeof newState[key].value!=="string")
        newState[key].value = !newState[key].value;
        else
        newState[key].value = !(newState[key].value==="true");
        setData(newState);
    }

    const addImageHandler = ()=>{
        fileRef.current.setAttribute("data-name","image");
        fileRef?.current?.click();
    }
    const addDocumentHandler = ()=>{
        fileRef.current.setAttribute("data-name","document");
        fileRef?.current?.click();
    }

    const cloneData = (data)=>{
        const newData = JSON.parse(JSON.stringify(data));

        newData.images= data.images.slice();
        newData.documents = data.documents.slice();

        return newData;
    }
    const onFileChange = async (event)=>{
       if(event?.target?.files?.length == 0) return;
   
        const newData = cloneData(data)

        if(event?.target?.dataset?.name=="image"&&newData?.images[newData?.images?.length - 1]?.name!==event?.target?.files[0]?.name){

            newData?.images?.push(event?.target?.files[0]);
            newData?.image_alt?.push({
                value:"",
                error:"",
                touch:false
            });
            newData?.image_object_ids?.push("");

        }

        if(event?.target?.dataset?.name=="document"&&newData?.documents[newData?.documents?.length - 1]?.name!==event?.target?.files[0]?.name){

            newData?.documents?.push(event?.target?.files[0]);
            newData?.document_strings?.push("");

        }

        setData(newData);
       fileRef.current.setAttribute("data-name","");
       fileRef.current.files=null;
    }

    const onRemoveImage = (event)=>{
        if(event?.target?.files?.length <= event?.target?.dataset?.index) return;

        let index = event?.target?.dataset?.index;

        const newData = cloneData(data);

        newData.images =  [...newData.images?.slice(0,index),...newData.images?.slice(parseInt(index)+1)];

        newData.image_alt =  [...newData.image_alt?.slice(0,index),...newData.image_alt?.slice(parseInt(index)+1)];
        
        newData.image_object_ids =  [...newData.image_object_ids?.slice(0,index),...newData.image_object_ids?.slice(parseInt(index)+1)];

        setData(newData);
    }

    const addAmmenityHandler = ()=>{
        const newData = cloneData(data);

        newData?.ammenities?.push({
            text:{
                value:"",
                error:"",
                touch:false
            },
            icon:0,
        });

        setData(newData);
    }

    const removeAmmenityHandler = (e)=>{
        let index = e?.target?.dataset?.index;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        newData.ammenities = [...newData?.ammenities?.slice(0,index),...newData?.ammenities?.slice(index+1)];

        setData(newData);
    }
    const addSection = ()=>{
        const newData = cloneData(data);

        newData?.sections?.push({
            heading:{
                value:"",
                error:"",
                touch:false
            },
            data:[],
        });

        setData(newData);
    }
    const addRecommendation = ()=>{
        const newData = cloneData(data);

        newData?.recommended?.push(0);

        setData(newData);
    }
    const addSubsection = (event)=>{
        let index = event?.target?.dataset?.index;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        newData?.sections[index]?.data?.push({
            heading:{
                value:"",
                error:"",
                touch:false
            },
            data:[],
        });

        setData(newData);
    }

    const addPointer = (event)=>{
        let index = event?.target?.dataset?.index;
        let index2 = event?.target?.dataset?.index2;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        newData?.sections[index]?.data[index2]?.data?.push({
                value:"",
                error:"",
                touch:false
        });

        setData(newData);
    }

    const onSectionHeadingChange = (e)=>{
        let index = e?.target?.dataset?.index;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        if(e?.target?.value==""){
            newData.sections[index].heading.error="Heading is required!";
        }
        else  newData.sections[index].heading.error="";

        newData.sections[index].heading.value = e?.target?.value;
        newData.sections[index].heading.touch = true;

        setData(newData);
    }
    const onSubsectionHeadingChange = (e)=>{
        let index = e?.target?.dataset?.index;
        let index2 = e?.target?.dataset?.index2;

        if(!index || !index2) return;

        index = parseInt(index);
        index2 = parseInt(index2);

        const newData = cloneData(data);

        if(e?.target?.value==""){
            newData.sections[index].data[index2].heading.error="Heading is required!";
        }
        else  newData.sections[index].data[index2].heading.error="";

        newData.sections[index].data[index2].heading.value = e?.target?.value;
        newData.sections[index].data[index2].heading.touch = true;

        setData(newData);
    }
    const onPointerChange = (e)=>{
        let index = e?.target?.dataset?.index;
        let index2 = e?.target?.dataset?.index2;
        let index3 = e?.target?.dataset?.index3;

        if(!index || !index2 || !index3) return;

        index = parseInt(index);
        index2 = parseInt(index2);
        index3 = parseInt(index3);

        const newData = cloneData(data);

        if(e?.target?.value==""){
            newData.sections[index].data[index2].data[index3].error="Heading is required!";
        }
        else  newData.sections[index].data[index2].data[index3].error="";

        newData.sections[index].data[index2].data[index3].value = e?.target?.value;
        newData.sections[index].data[index2].data[index3].touch = true;

        setData(newData);
    }
    const removeSectionHandler = (e)=>{
        let index = e?.target?.dataset?.index;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        newData.sections = [...newData?.sections?.slice(0,index),...newData?.sections?.slice(index+1)];

        setData(newData);
    }
    const removeRecommendationHandler = (e)=>{
        let index = e?.target?.dataset?.index;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        newData.recommended = [...newData?.recommended?.slice(0,index),...newData?.recommended?.slice(index+1)];

        setData(newData);
    }
    const removeSubsectionHandler = (e)=>{
        let index = e?.target?.dataset?.index;
        let index2 = e?.target?.dataset?.index2;


        if(!index||!index2) return;

        index = parseInt(index);
        index2 = parseInt(index2);

        const newData = cloneData(data);

        newData.sections[index].data = [...newData?.sections[index]?.data.slice(0,index2),...newData?.sections[index]?.data?.slice(index2+1)];

        setData(newData);
    }
    const removePointerHandler = (e)=>{
        let index = e?.target?.dataset?.index;
        let index2 = e?.target?.dataset?.index2;
        let index3 = e?.target?.dataset?.index3;


        if(!index||!index2||!index3) return;

        index = parseInt(index);
        index2 = parseInt(index2);
        index3 = parseInt(index3);

        const newData = cloneData(data);

        newData.sections[index].data[index2].data = [...newData?.sections[index]?.data[index2]?.data.slice(0,index3),...newData?.sections[index]?.data[index2]?.data?.slice(index3+1)];

        setData(newData);
    }

    const removeDocumentHandler = (e)=>{
        let index = e?.target?.dataset?.index;

        if(!index) return;

        index = parseInt(index);

        const newData = cloneData(data);

        newData.documents = [...newData.documents?.slice(0,index),...newData.documents?.slice(index+1)];
        newData.document_strings = [...newData.document_strings?.slice(0,index),...newData.document_strings?.slice(index+1)];

        setData(newData);
    }
    return <div className={styles["container"]}>
        <input type="file" ref={fileRef} style={{visibility:"hidden",display:"none"}} onChange={onFileChange}/>
        <div className={styles["card"]}>
            <div className={styles["content"]}>
                <div className={styles["heading"]}>
                    <div>
                        <H1 type="heading-small-regular">{isEdit?"Update":"Add"} Variant</H1>
                    </div>
                </div>
                <div className={styles["form"]}>
                    {
                        data?.images?.map((image,index)=>{
                            return <div className={styles["image_container"]}>
                                <div className={styles["image-remove"]} data-index={index} onClick={onRemoveImage}>-</div>
                                <img crossorigin="anonymous" key={index} src={data?.image_object_ids[index]?`${baseURL}${variantBase}/document?name=${image}`:URL.createObjectURL(image)} className={styles["image"]}/>
                                <div className={styles["form-element"]}>
                                    <div className={styles["label"]}>
                                    </div>
                                    <div className={styles["input-row"]}>
                                        <div className={styles["input"]}>
                                            <TextInput placeholder={"Describe above image"} value={data?.image_alt[index]?.value} error={data?.image_alt[index]?.error} onChange={onInputValueChange} name={"image_alt["+index+"]"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["form_cta"]} onClick={addImageHandler}>
                                <div>Add Image</div>
                                <div>
                                    <GoPlusCircle/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Type
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <DropDown2 name={"type"} onChange={onTypeFilterChange} steps={typeEnum} selectedValue={data?.type} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Occupancy date
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <input type="date"  value={data?.occupancy_date?.value} onChange={onInputValueChange} name="occupancy_date"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Beds
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"2"} value={data?.beds?.value} error={data?.beds?.error} onChange={onInputValueChange} name="beds"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Baths
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"2"} value={data?.baths?.value} error={data?.baths?.error} onChange={onInputValueChange} name="baths"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Price
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"300000"} value={data?.price?.value} error={data?.price?.error} onChange={onInputValueChange} name="price"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["realtor-check"]}>
                        <div>
                            <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex="includesGST" state={data?.includesGST?.value}/>
                        </div>
                        <div className={styles["realtor-text"]}>
                            Price includes GST?
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Sqft
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"1200"} value={data?.sqft?.value} error={data?.sqft?.error} onChange={onInputValueChange} name="sqft"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Highlight 1
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"500m from St Jospeh High School"} value={data?.highlights[0]?.text?.value} error={data?.highlights[0]?.text?.error} onChange={onInputValueChange} name="highlights[0]"/>
                            </div>
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <DropDown2 name={"highlights[0]"} onChange={onLocationFilterChange} steps={steps} selectedValue={data?.highlights[0]?.icon} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Highlight 2
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"500m from St Jospeh High School"} value={data?.highlights[1]?.text?.value} error={data?.highlights[1]?.text?.error} onChange={onInputValueChange} name="highlights[1]"/>
                            </div>
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <DropDown2 name={"highlights[1]"} onChange={onLocationFilterChange} steps={steps} selectedValue={data?.highlights[1]?.icon} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Highlight 3
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"500m from St Jospeh High School"} value={data?.highlights[2]?.text?.value} error={data?.highlights[2]?.text?.error} onChange={onInputValueChange} name="highlights[2]"/>
                            </div>
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <DropDown2 name={"highlights[2]"} onChange={onLocationFilterChange} steps={steps} selectedValue={data?.highlights[2]?.icon} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Highlight 4
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"500m from St Jospeh High School"} value={data?.highlights[3]?.text?.value} error={data?.highlights[3]?.text?.error} onChange={onInputValueChange} name="highlights[3]"/>
                            </div>
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <DropDown2 name={"highlights[3]"} onChange={onLocationFilterChange} steps={steps} selectedValue={data?.highlights[3]?.icon} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Description
                        </div>
                        <div className={styles["input-row-textarea"]}>
                            <div className={styles["input"]}>
                                <textarea placeholder={"Welcome to this exceptional David Weekley masterpiece! This stunning estate spans 3,816 square feet, offering 4 bedrooms, 3 full baths, and 1 half bath, all nestled on a picturesque 1.793-acre property. Step into the expansive backyard ."} value={data?.description?.value} error={data?.description?.error} onChange={onInputValueChange} name="description"/>
                                <div  className={styles["error"]}>{data?.description?.error}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    {
                        data?.ammenities?.map((ammenity,index)=>{
                            return <div className={styles["form-element"]} key={index}>
                            <div className={`${styles["label"]} ${styles["ammenity_label"]}`}>
                                <div>Amenity {index+1}</div>
                                <div onClick={removeAmmenityHandler} data-index={index}>-</div>
                            </div>
                            <div className={styles["input-row"]}>
                                <div className={styles["input"]}>
                                    <TextInput placeholder={"Free basement with side entrance"} value={ammenity?.text?.value} error={ammenity?.text?.error} onChange={onInputValueChange} name={"ammenities["+index+"]"}/>
                                </div>
                            </div>
                            <div className={styles["input-row"]}>
                                <div className={styles["input"]}>
                                    <DropDown2 name={"ammenities["+index+"]"} onChange={onLocationFilterChange} steps={steps} selectedValue={ammenity?.icon} selectedStyle={{border:"2px solid black",height:"calc(5 * var(--base-y)"}} stepStyle={{height:"calc(5 * var(--base-y)"}}/>
                                </div>
                            </div>
                        </div>
                        })
                    }
                    <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["form_cta"]} onClick={addAmmenityHandler}>
                                <div>Add Amenity</div>
                                <div>
                                    <GoPlusCircle/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Virtual Tour Link
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"https://www.virtual.com"} value={data?.virtual_tour_link?.value} error={data?.virtual_tour_link?.error} onChange={onInputValueChange} name="virtual_tour_link"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Youtube Link
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"https://www.youtube.com"} value={data?.youtube_link?.value} error={data?.youtube_link?.error} onChange={onInputValueChange} name="youtube_link"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    {
                        data?.documents?.map((document,index)=>{
                            return <div className={styles["form-element"]}>
                                <div className={styles["input-row"]}>
                                    <div className={styles["document"]}>
                                        {document?.name?.includes("og") && <div>{document?.name?.split("og")[1]?.length > 10?document?.name?.split("og")[1]:document?.name?.split("og")[1]?.slice(0,10)+"..."}</div>}
                                        {!document?.name?.includes("og") && <div>{document?.name?.length > 10?document?.name:document?.name?.slice(0,10)+"..."}</div>}
                                        <div>
                                            <div>
                                                <a href={data?.document_strings[index]?`${baseURL}${variantBase}/document?name=${data?.document_strings[index]}`:URL.createObjectURL(document)} target="_blank" style={{color:"inherit"}}>
                                                    <BsDownload/>
                                                </a>
                                            </div>
                                            <div onClick={removeDocumentHandler} data-index={index}>
                                                -
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        })
                    }
                    <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["form_cta"]} onClick={addDocumentHandler}>
                                <div>Add Document</div>
                                <div>
                                   <GoPlusCircle/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    {
                        data?.sections?.map((section,index)=>{
                            return <div className={styles["box"]}>
                                <div className={styles["form-element"]}>
                                    <div className={`${styles["label"]} ${styles["ammenity_label"]}`}>
                                        <div>Section {index+1} heading</div>
                                        <div onClick={removeSectionHandler} data-index={index}>-</div>
                                    </div>
                                    <div className={styles["input-row"]}>
                                        <div className={styles["input"]}>
                                            <TextInput index={index} onChange={onSectionHeadingChange} placeholder={"Interior"} value={data?.sections[index]?.heading?.value} error={data?.sections[index]?.heading?.error}  name={"sections["+index+"][heading]"}/>
                                        </div>
                                    </div>
                                </div>
                                {
                                    section?.data?.map((subsection,index2)=>{
                                        return <div className={styles["box"]}>
                                            <div className={styles["form-element"]}>
                                                <div className={`${styles["label"]} ${styles["ammenity_label"]}`}>
                                                    <div>Sub-Section {index2+1} heading</div>
                                                    <div onClick={removeSubsectionHandler} data-index={index} data-index2={index2}>-</div>
                                                </div>
                                                <div className={styles["input-row"]}>
                                                    <div className={styles["input"]}>
                                                        <TextInput index={index} index2={index2} onChange={onSubsectionHeadingChange} placeholder={"Bedrooms & Bathrooms"} value={data?.sections[index]?.data[index2]?.heading?.value} error={data?.sections[index]?.data[index2]?.heading?.error}  name={"sections["+index+"]["+index2+"][heading]"}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                subsection?.data?.map((pointer,index3)=>{
                                                    return <div className={styles["box"]}>
                                                        <div className={styles["form-element"]}>
                                                            <div className={`${styles["label"]} ${styles["ammenity_label"]}`}>
                                                                <div>Pointer {index3+1}</div>
                                                                <div onClick={removePointerHandler} data-index={index} data-index2={index2} data-index3={index3}>-</div>
                                                            </div>
                                                            <div className={styles["input-row"]}>
                                                                <div className={styles["input"]}>
                                                                    <TextInput index={index} index2={index2} index3={index3} onChange={onPointerChange} placeholder={"Bedrooms: 4"} value={data?.sections[index]?.data[index2]?.data[index3]?.value} error={data?.sections[index]?.data[index2]?.data[index3]?.error}  name={"sections["+index+"]["+index2+"]["+index3+"]"}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            <div className={styles["form-element"]}>
                                                <div className={styles["input-row"]}>
                                                    <div className={styles["form_cta"]} onClick={addPointer} data-index={index} data-index2={index2}>
                                                        <div>Add Pointer</div>
                                                        <div>
                                                            <GoPlusCircle/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                                <div className={styles["form-element"]}>
                                    <div className={styles["input-row"]}>
                                        <div className={styles["form_cta"]} onClick={addSubsection} data-index={index}>
                                            <div>Add Sub-Section</div>
                                            <div>
                                                <GoPlusCircle/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["form_cta"]} onClick={addSection}>
                                <div>Add Section</div>
                                <div>
                                    <GoPlusCircle/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["hr"]}></div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Accurate Location
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["half-input"]}>
                                <TextInput placeholder={"lat"} value={data?.accurate_location[0]?.value} error={data?.accurate_location[0]?.error} onChange={onInputValueChange} name="accurate_location[0]"/>
                            </div>
                            <div className={styles["half-input"]}>
                                <TextInput placeholder={"long"} value={data?.accurate_location[1]?.value} error={data?.accurate_location[1]?.error} onChange={onInputValueChange} name="accurate_location[1]"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Generic Location
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["half-input"]}>
                                <TextInput placeholder={"lat"} value={data?.generic_location[0]?.value} error={data?.generic_location[0]?.error} onChange={onInputValueChange} name="generic_location[0]"/>
                            </div>
                            <div className={styles["half-input"]}>
                                <TextInput placeholder={"long"} value={data?.generic_location[1]?.value} error={data?.generic_location[1]?.error} onChange={onInputValueChange} name="generic_location[1]"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["realtor-check"]}>
                        <div>
                            <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex="isRecommended" state={data?.isRecommended?.value}/>
                        </div>
                        <div className={styles["realtor-text"]}>
                            Is Property Recommended?
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Add an priority number
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"1"} value={data?.priorityNo?.value} error={data?.priorityNo?.error} onChange={onInputValueChange} name="priorityNo"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Meta title
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"3 Bed & 3 Bath Unit For Sale "} value={data?.meta?.title?.value} error={data?.meta?.title?.error} onChange={onInputValueChange} name="meta_title"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Meta Description
                        </div>
                        <div className={styles["input-row-textarea"]}>
                            <div className={styles["input"]}>
                                <textarea placeholder={"Welcome to this exceptional David Weekley masterpiece! This stunning estate spans 3,816 square feet, offering 4 bedrooms, 3 full baths, and 1 half bath, all nestled on a picturesque 1.793-acre property. Step into the expansive backyard ."} value={data?.meta?.description?.value} error={data?.meta?.description?.error} onChange={onInputValueChange} name="meta_description"/>
                                <div  className={styles["error"]}>{data?.meta?.description?.error}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["form-element"]}>
                        <div className={styles["label"]}>
                            Meta keywords
                        </div>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"Condo, Calgary, Peak60"} value={data?.meta?.keywords?.value} error={data?.meta?.keywords?.error} onChange={onInputValueChange} name="meta_keywords"/>
                            </div>
                        </div>
                    </div>
                </div>
                {data?.recommended?.map((recommend,idx)=><div className={styles["form-element"]}>
                        <div className={`${styles["label"]} ${styles["ammenity_label"]}`}>
                            <div>Recommendation {idx+1}</div>
                            <div onClick={removeRecommendationHandler} data-index={idx}>-</div>
                        </div>
                        <div className={styles["input-row"]} style={{marginBottom:"calc(5 * var(--base-y))"}}>
                            <div className={styles["input"]}>
                                <DropDown2 name={"recommended["+idx+"]"} onChange={onRecommendedFilterChange} steps={variantStrings} selectedValue={data?.recommended[idx]} selectedStyle={{border:"2px solid black",height:"calc(8 * var(--base-y)"}} stepStyle={{height:"calc(8 * var(--base-y)"}}/>
                            </div>
                        </div>
                </div>)}
                <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["form_cta"]} onClick={addRecommendation}>
                                <div>Add Recommendations</div>
                                <div>
                                    <GoPlusCircle/>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className={styles["cta"]}>
                        <div>
                            <Button onClick={onSubmit} disable={(data.beds.value==""||data.beds.error!==""||(!data.beds.touch&&!isEdit)||data.baths.value==""||data.baths.error!==""||(!data.baths.touch&&!isEdit))}>
                                {isEdit?"Update":"Add"}
                            </Button>
                        </div>
                        <div>
                            <div onClick={onClose} style={{cursor:"pointer"}}>
                                Close
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
}

export default VariantForm;