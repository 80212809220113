import styles from "./index.module.css";
import UnderLineButton from "../../elements/UnderLineButton";
import H1 from "../../typography/H1";
import { useEffect, useState } from "react";
import {  changeActiveStatusVariant, copyProject, copyVariant, deleteEmailForProjectAccessById, deleteProject, deleteVariant, fetchAllVariantsByFilters, fetchProjectById, postEmailForProjectAccess } from "../../../utils/apis.utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import DropDown2 from "../../elements/Dropdown2";
import Button from "../../elements/Button";
import { baseURL, permTokenIdentified, projectBase, userBase } from "../../../utils/constant";
import toast from "react-hot-toast";
import { GoPlusCircle } from "react-icons/go";
import TextInput from "../../elements/TextInput";
import Checkbox from "../../elements/Checkbox";

const VariantManagement = (props)=>{
    const [prospects,setProspects] = useState();
    const [refresh,setRefresh] = useState(false);
    const [selected,setSelected] = useState(0);
    const [access,setAccess] = useState(false);
    const [email,setEmail] = useState({
        value:"",
        error:""
    });
    const [emails,setEmails] = useState([]);

    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();

    useEffect(()=>{

        let id = searchParams.get("id");

        if(!id) return navigate(searchParams.get("backtrace"));

        fetchAllVariantsByFilters({project:id})
        .then(res=>{
            setProspects(res?.data?.data);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    },[refresh,selected]);

    useEffect(()=>{
        if(!access) return;

        let id = searchParams.get("id");

        if(!id) return navigate(searchParams.get("backtrace"));

        fetchProjectById(id)
        .then(res=>{
            setEmails(res?.data?.data?.shareList);
        })
        .catch(err=>{
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        })
    
    },[refresh,access]);

    const toggleUser = ()=>setRefresh(state=>!state);

    const deleteProjectHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await deleteVariant(id);
            toggleUser();
            toast.success("Variant deleted succesfully!")
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    
    const updateUserHandler = (event)=>{
        let id = event?.target?.dataset?.id;

        if(!id) return toast.error("Fail - refresh and try again!");

        navigate("/variant-form?projectId="+searchParams.get("id")+"&isEdit=true&backtrace=/admin/dashboard/variant-management?id="+searchParams.get("id")+"&id="+id);
    }

    const addUserHandler = (event)=>{
        navigate("/variant-form?projectId="+searchParams.get("id")+"&isEdit=false&backtrace=/admin/dashboard/variant-management?id="+searchParams.get("id"));
    }

    const backToProjectHandler = (event)=>{
        navigate("/admin/dashboard/project-management");
    }

    const copyProjectHandler = async (event)=>{
        const id = event?.target?.dataset?.id;
        
        try{
            await copyVariant(id);
            toggleUser();
            toast.success("Variant copied succesfully!");
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }

    const toggleAccess = ()=>setAccess(state=>!state);

    const onEmailChange = (e)=>{
        setEmail(state=>{
            let newState = JSON.parse(JSON.stringify(state));
            newState.value = e?.target?.value;

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailRegex.test(e?.target?.value)) {
                newState.error="";
            } else {
                newState.error="Invalid email"
            }

            return newState;
        });
    }

    const onEmailSubmit = async ()=>{
        if(email.value==""||email.error!="") return toast.error("Please enter a valid email!");

        try{
            await postEmailForProjectAccess({email:email?.value},searchParams.get("id"));
            toast.success("Email added succesfully!");
            toggleUser();
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }

    const onEmailDelete = async (event)=>{
        const emailId = event?.target?.dataset?.index;
        let projectId = searchParams.get("id");

        if(!emailId || !projectId) return toast.error("Unable to delete email! Try again!");

        try{
            await deleteEmailForProjectAccessById(emailId,projectId);
            toast.success("Access to project removed succesfully");
            toggleUser();
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }
    const onCheckboxValueChange =  async (e)=>{
        const index = e?.target?.dataset?.index;

        if(!index) return toast.error("Refresh and Try again!");

        try{
            await changeActiveStatusVariant({isActive:!prospects[index]?.isActive},prospects[index]?._id);

            if(!prospects[index]?.isActive)
            toast.success("variant made active");
            else
            toast.success("variant made inactive");

            toggleUser();
        }catch(err){
            if(err?.response?.data?.message)
            toast.error(err?.response?.data?.message);
            else 
            toast.error("Something went wrong!");
        }
    }   
    return <><div className={styles["container"]}>
        <div className={styles["heading"]}>
            <div>
                <H1 type="heading-small">Variant List</H1>
            </div>
            {window.innerWidth>850 &&<div className={styles["filters"]}>
                    <div>
                       <Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} onClick={backToProjectHandler}>Back</Button>
                    </div>
                    <div>
                        <Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} onClick={toggleAccess}>Access</Button>
                    </div>
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add Variant</Button>
                    </div>
            </div>}
        </div>
        <div className={styles["count"]}>
            {prospects?.length!==0 && <>Showing {prospects?.length} variants</>}
        </div>
        {window.innerWidth<=850 && <div className={styles["filters"]}>
                    <div>
                       <Button style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} onClick={backToProjectHandler}>Back</Button>
                    </div>
                    <div>
                        <Button onClick={addUserHandler} style={{borderRadius:"0rem",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>Add Variant</Button>
                    </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth>850 && <div className={styles["content"]}>
            <div className={styles["table"]}>
                <div className={styles["head-row"]}>
                    <div>Beds</div>
                    <div>Baths</div>
                    <div>Price</div>
                    <div>Sqft</div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    prospects?.map((prospect,index)=>{
                        return <div className={styles["rows"]}>
                        <div className={styles["row"]}>
                            <div>{prospect?.beds}</div>
                            <div>{prospect?.baths}</div>
                            <div>{prospect?.price}</div>
                            <div>{prospect?.sqft}</div>
                            <div>
                                <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex={index} state={prospect?.isActive}/>
                            </div>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProjectHandler} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={copyProjectHandler} id={prospect?._id}>
                                    copy
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {prospects?.length!==0 && window.innerWidth<=850 &&<div className={styles["content"]}>
            <div className={styles["table"]}>
                {
                    prospects?.map((prospect,index)=>{
                        return <div className={styles["mobile-rows"]}>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Beds</div>
                                <div>{prospect?.beds}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Baths</div>
                                <div>{prospect?.baths}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <div className={styles["label"]}>Price</div>
                                <div>{prospect?.price}</div>
                            </div>
                            <div>
                                <div className={styles["label"]}>Sqft</div>
                                <div>{prospect?.sqft}</div>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <Checkbox size="calc(2 * var(--base-x))" onClick={onCheckboxValueChange} dataIndex={index} state={prospect?.isActive}/>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={updateUserHandler} id={prospect?._id}>
                                    update
                                </UnderLineButton>
                            </div>
                            <div>
                                <UnderLineButton onClick={deleteProjectHandler} id={prospect?._id}>
                                    delete
                                </UnderLineButton>
                            </div>
                        </div>
                        <div className={styles["mobile-row"]}>
                            <div>
                                <UnderLineButton onClick={copyProjectHandler} id={prospect?._id}>
                                    copy
                                </UnderLineButton>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>}
        {
            prospects?.length==0 && <div className={styles["no-one"]}>
                No variants found!
            </div>
        }
    </div>
    {
        access && <div className={styles["background"]}>
            <div className={styles["card"]}>
                {
                        emails?.map((document,index)=>{
                            return <div className={styles["form-element"]}>
                                <div className={styles["input-row"]}>
                                    <div className={styles["document"]}>
                                        {document?.email?.length<10?document?.email:document?.email?.slice(0,20)+"..."}
                                        <div>
                                            <div onClick={onEmailDelete} data-index={index}>
                                                -
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        })
                }
                <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["input"]}>
                                <TextInput placeholder={"john@gmail.com"} value={email?.value} error={email?.error} onChange={onEmailChange} name="virtual_tour_link"/>
                            </div>
                        </div>
                </div>
                <div className={styles["form-element"]}>
                        <div className={styles["input-row"]}>
                            <div className={styles["form_cta"]} onClick={onEmailSubmit}>
                                <div>Add Email</div>
                                <div>
                                   <GoPlusCircle/>
                                </div>
                                
                            </div>
                        </div>
                </div>
                <div className={styles["cta"]}>
                    <div>
                        <Button onClick={toggleAccess}>
                                Close
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
}
export default VariantManagement;